import { AccountService } from '../../account/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'app/_services/general.service';
import { MixPanelEventEnum } from 'app/_models/mixPanelEventEnum';
import { NotificationService } from 'app/_services/notification.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SignalRService } from '../../_services/signalr.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

declare const crisp: any;
declare const isEmpty: any;

@Component({ selector: 'login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit
{
    loginForm: UntypedFormGroup;
    showPassword = false;
    isLoading = false;

    constructor(private accountService: AccountService, private notificationService:  NotificationService,
        private signalRService: SignalRService, private activatedRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder,
        private ngxLoader: NgxUiLoaderService, private router: Router, private translate: TranslateService,
        private generalService: GeneralService)
    {
        crisp.hide();

        this.accountService.onLoginFailedEvent.subscribe({ next: (ret) => this.setFailedLogin(ret) });
    }

    get f() { return this.loginForm.controls; }

    ngOnInit()
    {
        // Finaliza o signalR
        this.signalRService.finalizar();
        this.ngxLoader.stopLoader('loader-principal');

        this.loginForm = this.formBuilder.group({
            login: ['', Validators.required],
            senha: ['', Validators.required]
        });

        this.accountService.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    }

    efetuarLogin()
    {
        if (this.loginForm.invalid)
            return;

        this.isLoading = true;

        this.accountService.efetuarLogin(this.f.login.value, this.f.senha.value).subscribe({
            next: (loginResponse: any) =>
            {
                if (isEmpty(loginResponse) || !loginResponse.sucesso)
                {
                    this.setFailedLogin(null);
                    return;
                }

                this.accountService.executeLoginResponse({ user: loginResponse.retorno });

                let user = this.accountService.obterUsuario();
                this.generalService.updateUserPropertiesMixPanel(user).subscribe({ error: (e: any) => console.error(e) });
                this.generalService.trackMixPanelEvent(MixPanelEventEnum.LogIn).subscribe({ error: (e: any) => console.error(e) });
                crisp.login(user);

                this.isLoading = false;
            },
            error: error =>
            {
                if (error.statusCode === 404)
                {
                    this.setFailedLogin(null);
                    return;
                }

                console.log(error);
                this.translate.get('geral.erro.erro-desconhecido').subscribe(t => this.notificationService.showErrorToastr(t));
                this.isLoading = false;
            }
        });
    }

    private setFailedLogin(message: string) {
        this.isLoading = false;

        if (!message)
            this.translate.get('login.mensagens.usuario-senha-invalido').subscribe(t => this.notificationService.showErrorToastr(t));
        else
            this.translate.get(message).subscribe(t => this.notificationService.showErrorToastr(t));

        this.f.senha.setValue('');
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
